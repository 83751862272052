import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import TopSlider from '@components/top-slider/index';
import ServicesSlider from '@components/services-slider/index';
import {
  Section,
  Subtitle,
  Title,
  Paragraph,
  Container,
} from '@components/styledComponents/index';
import {
  ClientsBox,
  ClientLogo,
  /* TestimonialBox,
  TestimonialText,
  CustomerInfo,
  CustomerDetails,
  CustomerName,
  CustomerPosition,
  CustomerLogo,
  CustomerImage,
  CustomerDetailsBox, */
  OurService,
} from './styles';

const Home = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <TopSlider />
      <Container
        bgColor={theme.secondaryBgColor}
        deskMargin={'0 15rem'}
        deskDir={'row-reverse'}
      >
        <Section padding={'0'} deskMargin={'0 0 0 0'}>
          <Section
            padding={'2.7rem 1.4rem 1.5rem'}
            deskPadding={'2.7rem 1.4rem 0rem'}
          >
            <Subtitle>Our clients</Subtitle>
            <Title>
              From the largest multinationals to regional start-ups...
            </Title>
            <Paragraph deskMaxWidth={'54.3rem'}>
              We help our clients unlock the potential of their digital
              transformation to generate growth and empower their competitive
              advantage.
            </Paragraph>
          </Section>
          <Section padding={'0'} deskPadding={'2.7rem 1.4rem 3.5rem'}>
            <ClientsBox>
              <ClientLogo
                alt="Samba"
                src={require('@images/clients/samba.png')}
                srcSet={`${require('@images/clients/samba.png')}, ${require('@images/clients/sambax2.png')} 2x`}
              />
              <ClientLogo
                alt="Al Marai"
                src={require('@images/clients/almarai.png')}
                srcSet={`${require('@images/clients/almarai.png')}, ${require('@images/clients/almaraix2.png')} 2x`}
              />
              <ClientLogo
                alt="Arabia ANB"
                src={require('@images/clients/arabi_anb.png')}
                srcSet={`${require('@images/clients/arabi_anb.png')}, ${require('@images/clients/arabi_anbx2.png')} 2x`}
              />
              <ClientLogo
                alt="Aljazira Bank"
                src={require('@images/clients/aljazira-bank.png')}
                srcSet={`${require('@images/clients/aljazira-bank.png')}, ${require('@images/clients/aljazira-bankx2.png')} 2x`}
              />
              <ClientLogo
                alt="Alrajhi Bank"
                src={require('@images/clients/alrajhi-bank.png')}
                srcSet={`${require('@images/clients/alrajhi-bank.png')}, ${require('@images/clients/alrajhi-bankx2.png')} 2x`}
              />
              <ClientLogo
                alt="Saudi Fransi Bank"
                src={require('@images/clients/saudi-fransi-bank.png')}
                srcSet={`${require('@images/clients/saudi-fransi-bank.png')}, ${require('@images/clients/saudi-fransi-bankx2.png')} 2x`}
              />
            </ClientsBox>
          </Section>
        </Section>
        {/* <Section>
          <TestimonialBox>
            <TestimonialText>
              “Thank you so much for your visit last week and for your honest,
              clear and professional advice. We were very impressed with how
              down-to-earth and straightforward you were in sharing your
              knowledge of planning etc with us. Having visited a previous
              client of Offset today, speaking with her and seeing the work
              done, we are now even more impressed and would like to use Offset
              for plans”
            </TestimonialText>
            <CustomerInfo>
              <CustomerDetailsBox>
                <CustomerImage>
                  <img src={require('@images/client.png')} alt="One of our clients" />
                </CustomerImage>

                <CustomerDetails>
                  <CustomerName>Simon Barnes</CustomerName>
                  <CustomerPosition>VP of Technology</CustomerPosition>
                </CustomerDetails>
              </CustomerDetailsBox>
              <CustomerLogo
                alt="One of our customer logo"
                src={require('@images/clients/mandiri-bank.png')}
                srcSet={`${require('@images/clients/mandiri-bank.png')}, ${require('@images/clients/mandiri-bankx2.png')} 2x`}
              />
            </CustomerInfo>
          </TestimonialBox>
        </Section> */}
      </Container>
      <Container>
        <OurService>
          <div>
            <Subtitle>Our services</Subtitle>
            <Title>Digital Transformation</Title>
          </div>
          <div>
            <Paragraph>
              We advise clients on IT strategy, business case development and
              cost rationalization, and then help clients deliver on that
              strategy, from governance to quality assurance and logistics. We
              tailor customized solutions for clients through our CIO-on-tap
              verticals: CIO-on-tap advisory and CIO-on-tap execution. Each
              vertical is comprised of modules, each module can be available
              separately for you, the customer, to mix and match according to
              your needs.
            </Paragraph>
          </div>
        </OurService>
      </Container>
      <Container>
        <Section padding={'1.7rem 1.4rem 3.5rem'} deskPadding={'0'}>
          <ServicesSlider />
        </Section>
      </Container>
      {/* <Container bgColor={theme.secondaryBgColor}>
        <Section>
          <Subtitle margin={'0 0 2.9rem 0'} deskAlign="center">Awards &#38; Recognition</Subtitle>
          <Slider>
            <Slide>
              <SlideImage
                src={require('@images/awards/ohio-history-connection.jpg')}
                srcSet={`${require('@images/awards/ohio-history-connection.jpg')}, ${require('@images/awards/ohio-history-connection@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>Ohio History Connection Preservation Merit Award</SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                <SlideMore to={'/'}>Know more</SlideMore>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/awards/richard.jpg')}
                srcSet={`${require('@images/awards/richard.jpg')}, ${require('@images/awards/richard@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>When is the Best Time to Stop Testing?</SlideTitle>
                <SlideText>
                  A high quality product that meets customer needs is the
                  ultimate common goal that all testing teams thrive to achieve.
                  Testing activities and processes should be well planned,
                  prepared, executed and effectively tracked and monitored to
                  ensure meeting that goal, and test management…
                </SlideText>
                <SlideMore to={'/'}>Know more</SlideMore>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/awards/afs-award.jpg')}
                srcSet={`${require('@images/awards/afs-award.jpg')}, ${require('@images/awards/afs-award@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  Failure and Beyond: How Software Quality Might Cost You Your
                  Life!
                </SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                <SlideMore to={'/'}>Know more</SlideMore>
              </div>
            </Slide>
          </Slider>
          <Section deskDirection={'row'} flexAlign={'center'}>
            <Button width={'21.3rem'}>Show More</Button>
          </Section>
        </Section>
      </Container> */}
    </>
  );
};

export default Home;
