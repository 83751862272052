import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Home from '@components/pages/home/index';

const IndexPage = (props) => {
  return (
    <Layout {...props}>
      <SEO title="Home" />
      <Home />
    </Layout>
  );
};

export default IndexPage;
