import { Section } from '@components/styledComponents/index';
import styled, { css } from 'styled-components';

export const ClientsBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* @media (min-width: 1200px) {
    max-width: 54.3rem;
  } */
`;

export const ClientLogo = styled.img`
  height: 3.4rem;
  margin: 2rem 1.6rem;
  outline: none;
  width: 8.6rem;
  @media (min-width: 1200px) {
    width: 11.9rem;
    height: 4.7rem;
  }
`;

export const TestimonialBox = styled.div`
  box-sizing: border-box;
  background: ${props => props.bgColor || props.theme.bgColor}
    url(${require('@images/quote.svg')}) no-repeat 0.8rem 1.4rem;
  background-size: 4.8rem 3.6rem;
  padding: 3.6rem 2rem 2.4rem;
  @media (min-width: 1200px) {
    max-width: 55.4rem;
    padding: 5.2rem 3.6rem 3rem;
    background-size: 8rem 6.1rem;
    ${props =>
      props.full &&
      css`
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        max-width: 100%;
        background-position: 51.8rem 1.4rem;
      `};
  }
`;

export const TestimonialText = styled.div`
  font-family: 'Avenir Book Oblique';
  font-size: 1.4rem;
  color: ${props => props.theme.quoteTxtColor};
  letter-spacing: 0;
  line-height: 2.2rem;
  @media (min-width: 1200px) {
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 3.3rem;
  }
`;

export const CustomerDetailsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 4px;
  ${props =>
    props.full &&
    css`
      width: 22.5rem;
    `};
`;

export const CustomerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;
  @media (min-width: 1200px) {
    margin-top: 4.7rem;
    align-items: center;
    ${props =>
      props.full &&
      css`
        flex-direction: column;
        margin-right: 9.6rem;
        margin-left: 15rem;
        align-items: flex-start;
      `};
  }
`;

export const CustomerImage = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4rem;
  height: 4.2rem;
  overflow: hidden;
  width: 4.2rem;
  img {
    width: 4.3rem;
    height: 4.3rem;
  }
  @media (min-width: 1200px) {
    height: 7rem;
    width: 7rem;
    img {
      height: 7.1rem;
      width: 7.1rem;
    }
  }
`;

export const CustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.7rem;
  @media (min-width: 1200px) {
    margin-left: 1.2rem;
  }
`;

export const CustomerName = styled.h3`
  color: ${props => props.theme.txtColor};
  margin: 0;
  font-family: 'Avenir';
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.7rem;
  @media (min-width: 1200px) {
    font-size: 1.8rem;
    letter-spacing: 0;
    line-height: 2.8rem;
  }
`;

export const CustomerPosition = styled.h4`
  font-family: 'Avenir';
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.paragraphColor};
  letter-spacing: 0;
  @media (min-width: 1200px) {
    font-size: 1.8rem;
  }
`;

export const CustomerLogo = styled.img`
  height: 3.4rem;
  width: 8.6rem;
  @media (min-width: 1200px) {
    width: 12rem;
    height: 4.7rem;
  }
`;

export const OurService = styled(Section)`
  padding: 3rem 1.6rem 0;
  @media (min-width: 1200px) {
    flex-direction: row;
    margin: 10.9rem 15rem 13.2rem;
    > div:nth-child(2) {
      margin-left: 5.7rem;
    }
  }
`;
