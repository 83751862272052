import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled.div`
  .carousel-slider {
    padding-bottom: 5rem;
    .control-dots {
      .dot {
        box-shadow: none;
        width: 1rem;
        height: 1rem;
        background-color: ${props => props.theme.quoteTxtColor};
        opacity: 0.2;
        transition: opacity 0.5s linear;
        &.selected {
          opacity: 1;
        }
      }
    }
  }
  .carousel {
    .slide {
      background-color: ${props => props.theme.white};
    }
  }
`;

export const Image = styled.img`
  height: 18rem;
  object-fit: cover;
`;

export const Slide = styled.div`
  width: 100%;
  background: ${props => props.theme.quoteTxtColor}
    url(${require('@images/logo_inbg.svg')}) no-repeat -6.6rem 18rem;
  background-size: 27rem 21.8rem;
  box-sizing: border-box;
  text-align: left;
  visibility: visible;
  opacity: 1;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  > div {
    padding: 1.8rem 1.4rem 2.4rem;
    min-height: 24.9rem;
  }
  @media (min-width: 1200px) {
    background: none;
    width: 100%;
    position: relative;
    margin-bottom: 17.9rem;
    > div {
      background: ${props => props.theme.quoteTxtColor}
        url(${require('@images/logo_inbg.svg')}) no-repeat;
      background-size: 74rem 43.2rem;
      box-sizing: border-box;
      position: absolute;
      ${props =>
        props.reverse
          ? css`
              background-position: 45rem 0;
              right: 0;
              padding: 10.7rem 0 10.7rem 5.6rem;
            `
          : css`
              background-position: -21.5rem 0;
              left: 0;
              padding: 10.7rem 0 10.7rem 15rem;
            `};
      width: 66.6rem;
      > * {
        width: 43.6rem;
      }
    }
    ${Image} {
      width: 84.8rem;
      height: 55.9rem;
      ${props =>
        props.reverse
          ? css`
              float: left;
              margin-left: 15rem;
            `
          : css`
              float: right;
              margin-right: 15rem;
            `};
    }
  }
`;

export const Subtitle = styled.h3`
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.2rem;
  color: ${props => props.theme.sliderTitle};
  letter-spacing: 0.185rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  margin: 0;

  @media (min-width: 1200px) {
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }
`;

export const Title = styled.h2`
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 2.2rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 4.2rem;
  margin: 0;

  @media (min-width: 1200px) {
    font-family: 'Heuristica';
    font-weight: 400;
    font-size: 3.2rem;
    margin: 1.8rem 0 1.1rem 0;
  }
`;

export const Text = styled.p`
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 2.1rem;
  margin: 0 0 1rem 0;

  @media (min-width: 1200px) {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin: 0 0 1.9rem 0;
  }
`;

export const More = styled(Link)`
  padding-right: 1.3rem;
  color: ${props => props.theme.moreLink};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-decoration: none;
  background: url(${require('@images/arrow-right.svg')}) no-repeat right center;

  @media (min-width: 1200px) {
    font-size: 1.6rem;
  }
`;

export const ListingBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    ${Slide} {
      &:last-child {
        margin-bottom: 11.1rem;
      }
    }
  }
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15rem;
`;

export const TabsNav = styled.div`
  display: flex;
  box-shadow: inset -1px -4px 0px -2px ${props => props.theme.tabBorder};
  justify-content: space-between;
  margin-bottom: 4.6rem;
  cursor: pointer;
`;

export const TabItem = styled.div`
  border-bottom: 0.2rem solid ${props => props.selected ? props.theme.primaryColor : 'transparent'};
  color: ${props => props.selected ? props.theme.primaryColor : props.theme.txtColor};
  font-family: 'Heuristica';
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: 0;
  padding: 1.1rem 0;
`;

export const TabContent = styled.div`
  position: relative;
  height: 38.6rem;
  margin-bottom: 10rem;
  ${Slide} {
    transition: all 0.4s ease-in;
    position: absolute;
    > div {
      width: 57rem;
      height: 38.5rem;
      padding: 8.4rem 0 8.4rem 5.7rem;
      background-position: -16.9rem 5rem;
      > * {
        width: 45.4rem;
      }
    }
  }
  ${Image} {
    width: 57rem;
    height: 38.5rem;
    margin-right: 0;
  }
`;