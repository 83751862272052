import React, { useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import AppContext from '@utils/app-context';
import { desktopBaseWidth } from '@utils/consts';
import {
  Container,
  Slide,
  Image,
  Text,
  Subtitle,
  Title,
  More,
  ListingBox,
  TabsBox,
  TabsNav,
  TabItem,
  TabContent,
} from './styles';
import { isBrowser } from '@utils/helpers';

const content = [
  {
    id: 1,
    image: require('@images/services/vendor-management.jpg'),
    image2x: require('@images/services/vendor-management@2x.jpg'),
    subtitle: 'The optimal orchestration',
    title: 'Vendor Management',
    desc: `We provide vendor management services that help clients find the right
    mix of vendors for their IT projects — and keep those vendors working
    at optimal levels.`,
    link: '/services/vendor-management',
  },
  {
    id: 2,
    image: require('@images/services/project-management.jpg'),
    image2x: require('@images/services/project-management@2x.jpg'),
    subtitle: 'Solutions that deliver',
    title: 'Project Management',
    desc: `We offer full project management services with the ultimate goal of
    delivering a project’s scope requirements on time, within budget and
    with high-quality results.`,
    link: '/services/project-management',
  },
  {
    id: 3,
    image: require('@images/services/quality-assurance.jpg'),
    image2x: require('@images/services/quality-assurance@2x.jpg'),
    subtitle: 'Solutions that deliver',
    title: 'Making IT your business',
    desc: `Our quality assurance and testing services ensure your IT solution
    matches the requirements of the business and that expectations are met and surpassed.`,
    link: '/services/quality-assurance',
  },
  {
    id: 4,
    image: require('@images/services/logistics.jpg'),
    image2x: require('@images/services/logistics@2x.jpg'),
    subtitle: 'Coordinating the details',
    title: 'Logistics',
    desc: `Wareef offers logistics support services by arranging for visa processing, as
     well as room, board and transportation for any skilled resources that need to be outsourced 
     from abroad for an IT project. For the past 15 years.`,
    link: '/services/logistics',
  },
  {
    id: 5,
    image: require('@images/services/local-arabic-content.jpg'),
    image2x: require('@images/services/local-arabic-content@2x.jpg'),
    subtitle: 'Wareef is at home in GCC',
    title: 'Local Arabic Content',
    desc: `With a team of native Arabic speakers, Wareef ensures the bilingual
    nature of its IT projects, as well as the management of those
    projects.`,
    link: '/services/local-arabic-content',
  },
];

const CarouselSlider = () => (
  <Carousel
    showStatus={false}
    infiniteLoop={true}
    showArrows={false}
    interval={5000}
    autoPlay={false}
    showThumbs={false}
  >
    {content.map(item => (
      <Slide key={item.id}>
        <Image
          alt={item.image}
          src={item.image}
          srcSet={`${item.image}, ${item.image2x} 2x`}
        />
        <div>
          <Subtitle>{item.subtitle}</Subtitle>
          <Title>{item.title}</Title>
          <Text>{item.desc}</Text>
          <More to={item.link}>Know more</More>
        </div>
      </Slide>
    ))}
  </Carousel>
);

const ListingSlider = () => (
  <ListingBox>
    {content.map((item, index) => (
      <Slide key={item.id} reverse={index % 2}>
        <Image
          alt={item.image}
          src={item.image}
          srcSet={`${item.image}, ${item.image2x} 2x`}
        />
        <div>
          <Subtitle>{item.subtitle}</Subtitle>
          <Title>{item.title}</Title>
          <Text>{item.desc}</Text>
          <More to={item.link}>Know more</More>
        </div>
      </Slide>
    ))}
  </ListingBox>
);

const Tabs = () => {
  const tabs = [
    'Vendor Management',
    'Project Management',
    'Quality Assurance',
    'Logistics',
    'Local Arabic Content',
  ];
  const [selected, setSelected] = useState(0);

  return (
    <TabsBox>
      <TabsNav>
        {tabs.map((item, index) => (
          <TabItem
            key={item}
            selected={index == selected}
            onClick={() => setSelected(index)}
          >
            {item}
          </TabItem>
        ))}
      </TabsNav>
      <TabContent>
        {content.map((item, index) => (
          <Slide key={item.id} className={index !== selected ? 'hidden' : ''}>
            <Image
              src={item.image}
              srcSet={`${item.image}, ${item.image2x} 2x`}
            />
            <div>
              <Subtitle>{item.subtitle}</Subtitle>
              <Title>{item.title}</Title>
              <Text>{item.desc}</Text>
              <More to={item.link}>Know more</More>
            </div>
          </Slide>
        ))}
      </TabContent>
    </TabsBox>
  );
};

const ServicesSlider = props => {
  const {
    size: { width },
  } = useContext(AppContext);

  if (!isBrowser()) {
    return <></>;
  }

  if (props.tabs && width > desktopBaseWidth) {
    return (
      <Container>
        <Tabs />
      </Container>
    );
  }

  return (
    <Container>
      {width > desktopBaseWidth ? <ListingSlider /> : <CarouselSlider />}
    </Container>
  );
};

export default ServicesSlider;
