import styled from 'styled-components';

export const Container = styled.div`
  .control-dots {
    .dot {
      width: 1rem;
      height: 1rem;
    }
    @media (min-width: 1200px) {
      padding-left: 15rem;
      margin-bottom: 0;
      text-align: left;
    }
  }
  .carousel {
    .slide {
      background-color: transparent;
      height: 100%;
      @media (min-width: 1200px) {
        max-height: 61.3rem;
        height: 100%;
      }
    }
  } 
`;

export const Slide = styled.div`
  width: 100%;
  height: 21rem;
  background: transparent;
  @media (min-width: 1200px) {
    position: relative;
    height: 61.3rem;
  }
`;

export const SlideImage = styled.img`
  min-height: 21rem;
  object-fit: cover;
  @media (min-width: 1200px) {
    height: 61.3rem;
  }
`;

export const Text = styled.div`
  align-items: center;
  background: none;
  bottom: 0;
  color: ${props => props.theme.white};
  font-family: Helvetica;
  font-size: 1.6rem;
  justify-content: center;
  display: flex;
  letter-spacing: 0;
  line-height: 2.4rem;
  left: 0;
  position: absolute;
  padding: 2rem;
  right: 0;
  top: 0;
  text-align: center;
  transition: opacity .35s ease-in-out;
  width: 100%;
  >* {
    text-shadow: 0 0.2rem 0.6rem ${props => props.theme.black};
  }
  @media (min-width: 1200px) {
    font-size: 3.4rem;
    line-height: 4.4rem;
    padding: 2rem 24.8rem;
  }
`;

export const DotBox = styled.button`
  background-color: ${props => props.selected ? props.theme.menuSelectedColor : 'transparent'};
  border: 0;
  color: ${props => props.selected ? props.theme.txtColor : props.theme.menuSelectedColor};
  cursor: pointer;
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.136rem;
  line-height: 2rem;
  outline: none;
  height: 4.8rem;
  width: 4.8rem;
`;