import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import AppContext from '@utils/app-context';
import { desktopBaseWidth } from '@utils/consts';
import { Container, DotBox, Slide, SlideImage, Text } from './styles';

const indicator = (clickHandler, isSelected, index) => (
  <DotBox selected={isSelected} onClick={clickHandler}>
    {index < 10 ? `0${index + 1}` : index + 1}
  </DotBox>
);

const TopSlider = () => {
  const {
    size: { width },
  } = useContext(AppContext);

  return (
    <Container>
      <Carousel
        showStatus={false}
        infiniteLoop={true}
        showArrows={false}
        interval={5000}
        autoPlay={false}
        showThumbs={false}
        renderIndicator={width > desktopBaseWidth ? indicator : undefined}
      >
        <Slide>
          <SlideImage alt="innovative ideas with deep business image" src={require('@images/slide1.jpg')} />
          <Text>
            <p>
              We combine innovative ideas with deep business knowledge and
              technology to deliver business-led solutions in the digital future
            </p>
          </Text>
        </Slide>
        <Slide>
          <SlideImage alt="innovative ideas with deep business image" src={require('@images/slide2.png')} />
          <Text>
            <p>
              We combine innovative ideas with deep business knowledge and
              technology to deliver business-led solutions in the digital future
            </p>
          </Text>
        </Slide>
        <Slide>
          <SlideImage alt="innovative ideas with deep business image" src={require('@images/slide1.jpg')} />
          <Text>
            <p>
              We combine innovative ideas with deep business knowledge and
              technology to deliver business-led solutions in the digital future
            </p>
          </Text>
        </Slide>
      </Carousel>
    </Container>
  );
};

export default TopSlider;
